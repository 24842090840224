<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option label="拼班中" :value="0"></el-option>
          <el-option label="拼班成功" :value="1"></el-option>
          <el-option label="拼班失败" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="addGroup">发起拼班</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #time="{ row }">
        <div>
          <p>时段:{{ row.time_slot }}</p>
          <p>预计上课时间:{{ row.expect_start_time }}</p>
        </div>
      </template>
      <template #count="{ row }">
        <div>
          <p>
            已拼{{ row.map.users ? row.map.users.length : 0 }}/{{
              row.max_count
            }}人
          </p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 0"
            >拼班中</el-tag
          >
          <el-tag type="success" size="small" v-if="row.status === 1"
            >拼班成功</el-tag
          >
          <el-tag type="info" size="small" v-if="row.status === 2"
            >拼班失败</el-tag
          >
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="openInfo(row)"
            >详情</el-button
          >
          <el-button
            v-if="row.status == 0"
            type="success"
            size="small"
            @click="updateStatus(row, 1)"
            >拼班成功</el-button
          >
          <el-button
            v-if="row.status == 0"
            type="warning"
            size="small"
            @click="updateStatus(row, 2)"
            >拼班失败</el-button
          >
          <!-- <el-button  type="danger" size="small" @click="del(row)"
            >删除</el-button
          > -->
        </div>
      </template>
    </auto-table>


    
<el-dialog
  title="提示"
  :visible.sync="infoShow"
  width="50%">
    <el-descriptions class="margin-top" title="拼班信息" :column="3" >
    <el-descriptions-item label="课程">{{course.title}}</el-descriptions-item>
     <el-descriptions-item label="上课时间">{{course.time_slot}}</el-descriptions-item>
    <el-descriptions-item label="开班日期">{{course.expect_start_time}}</el-descriptions-item>
     <el-descriptions-item label="截至日期">
     {{cur.end_time}}
    </el-descriptions-item>
     <el-descriptions-item label="城市">{{cur.city}}</el-descriptions-item>
    <el-descriptions-item label="地址">{{cur.address}}</el-descriptions-item>
   
  </el-descriptions>
  <div class="avas" v-if="cur.map && cur.map.users">
    <p>已拼{{cur.map.users.length}}人</p>
    <img class="ava" v-for="(u,i) in cur.map.users.slice(0,20)" :key="i" :src="u.avatar || defaultPic" :alt="u.nick_name">
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="infoShow = false">取 消</el-button>
  
  </span>
</el-dialog>



    <createGroup ref="create" @success="getList(1)"/>
  </div>
</template>

<script>
import createGroup from "./components/createGroup.vue";

export default {
  components: {
    createGroup,
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "活动名称", value: "name" },
        { name: "拼班人数", value: "count", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "上课时间", value: "time", type: "custom" },
        { name: "截至时间", value: "end_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 0,
      infoShow:false,
      cur:{},
      course:{},
      stu:[],
      defaultPic:'https://img.curiousmore.com/1723426945107.png'
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    openInfo(row){
        this.cur = row
        this.stu = row.map.users
        this.course = row.map.course || row.map.need
        this.infoShow = true
    },
    addGroup() {
      this.$refs.create.open();
    },
    updateInfo(row) {
      this.$router.push({ name: "addActivity", query: { aid: row.id } });
    },
    del(row) {
      this.$confirm("确定删除").then(() => {
        this.$post("/user/businessActivityType/update", {
          id: row.id,
          status: 3,
        }).then((res) => {
          this.getList(1);
        });
      });
    },
    updateStatus(row, status) {
        let t = '确认拼班成功，成功后学员可支付尾款。'
        if(status == 2){
            t = '确认拼班失败，同意后将退回学员的定金'
        }

      this.$confirm(t).then(() => {
        const loading = this.$loading({
          lock: true,
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$post("/user/userCourseGroup/updateRes", {
          id: row.id,
          status,
        }).then((res) => {
            loading.close()
          this.getList(1);
        }).catch(err=>{
            loading.close()
        })
      });
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/userCourseGroup/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.avas{
  display: flex;
  flex-wrap: wrap;

}
.ava{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 10px;
}
</style>