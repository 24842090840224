var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-select',{attrs:{"placeholder":"状态","clearable":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('el-option',{attrs:{"label":"拼班中","value":0}}),_c('el-option',{attrs:{"label":"拼班成功","value":1}}),_c('el-option',{attrs:{"label":"拼班失败","value":2}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.addGroup}},[_vm._v("发起拼班")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"time",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v("时段:"+_vm._s(row.time_slot))]),_c('p',[_vm._v("预计上课时间:"+_vm._s(row.expect_start_time))])])]}},{key:"count",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v(" 已拼"+_vm._s(row.map.users ? row.map.users.length : 0)+"/"+_vm._s(row.max_count)+"人 ")])])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.status === 0)?_c('el-tag',{attrs:{"type":"info","size":"small"}},[_vm._v("拼班中")]):_vm._e(),(row.status === 1)?_c('el-tag',{attrs:{"type":"success","size":"small"}},[_vm._v("拼班成功")]):_vm._e(),(row.status === 2)?_c('el-tag',{attrs:{"type":"info","size":"small"}},[_vm._v("拼班失败")]):_vm._e()],1)]}},{key:"handler",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.openInfo(row)}}},[_vm._v("详情")]),(row.status == 0)?_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":function($event){return _vm.updateStatus(row, 1)}}},[_vm._v("拼班成功")]):_vm._e(),(row.status == 0)?_c('el-button',{attrs:{"type":"warning","size":"small"},on:{"click":function($event){return _vm.updateStatus(row, 2)}}},[_vm._v("拼班失败")]):_vm._e()],1)]}}])}),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.infoShow,"width":"50%"},on:{"update:visible":function($event){_vm.infoShow=$event}}},[_c('el-descriptions',{staticClass:"margin-top",attrs:{"title":"拼班信息","column":3}},[_c('el-descriptions-item',{attrs:{"label":"课程"}},[_vm._v(_vm._s(_vm.course.title))]),_c('el-descriptions-item',{attrs:{"label":"上课时间"}},[_vm._v(_vm._s(_vm.course.time_slot))]),_c('el-descriptions-item',{attrs:{"label":"开班日期"}},[_vm._v(_vm._s(_vm.course.expect_start_time))]),_c('el-descriptions-item',{attrs:{"label":"截至日期"}},[_vm._v(" "+_vm._s(_vm.cur.end_time)+" ")]),_c('el-descriptions-item',{attrs:{"label":"城市"}},[_vm._v(_vm._s(_vm.cur.city))]),_c('el-descriptions-item',{attrs:{"label":"地址"}},[_vm._v(_vm._s(_vm.cur.address))])],1),(_vm.cur.map && _vm.cur.map.users)?_c('div',{staticClass:"avas"},[_c('p',[_vm._v("已拼"+_vm._s(_vm.cur.map.users.length)+"人")]),_vm._l((_vm.cur.map.users.slice(0,20)),function(u,i){return _c('img',{key:i,staticClass:"ava",attrs:{"src":u.avatar || _vm.defaultPic,"alt":u.nick_name}})})],2):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.infoShow = false}}},[_vm._v("取 消")])],1)],1),_c('createGroup',{ref:"create",on:{"success":function($event){return _vm.getList(1)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }