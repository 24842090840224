<template>
  <div>
    <el-dialog title="创建拼班" :visible.sync="dialogVisible" width="50%">
      <el-form label-width="120px" ref="form">
       
        <div>
          <el-form-item label="课程类目">
            <el-radio-group v-model="categories">
              <el-radio label="体育运动">体育运动</el-radio>
              <el-radio label="休闲生活">休闲生活</el-radio>
              <el-radio label="夜校">夜校</el-radio>
              <!-- <el-radio label="陪练">陪练</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <div class="flex">
           
            <el-form-item label="课程">
             <el-input v-model="key" placeholder="课程名称"></el-input>
            </el-form-item>
             <el-form-item label="机构">
              <mechanism-select :mecid.sync="mecid"></mechanism-select>
            </el-form-item>
             <el-form-item label="">
              <el-button type="primary" @click="getCourseList(1)">搜索</el-button>
            </el-form-item>
          </div>
          <auto-table
            :DataList="DataList"
            :option="Option"
            :total="Total"
            @changePage="getCourseList"
          >
            <template #activity_time="{ row }">
              <div>
                <p>开始:{{ row.start_time }}</p>
                <p>结束:{{ row.end_time }}</p>
              </div>
            </template>
            <template #handler="{ row }">
              <div>
                <el-button
                  type="primary"
                  size="small"
                  @click="changeCourse(row)"
                  >选择</el-button
                >
              </div>
            </template>
          </auto-table>
        </div>
        <el-form-item label="课程" required>
          <p v-if="curCourse.id">
             <span class="bold">{{ curCourse.title }}</span>-{{ curCourse.map.masterMechanismEntity.mechanism_name }}({{curCourse.city}})
          </p>
          <p v-else>请选择课程</p>
         
        </el-form-item>
        <el-form-item label="拼班人数" required>
          <el-input v-model="form.max_count" placeholder="拼班人数"></el-input>
        </el-form-item>
        <el-form-item label="上课时间段" required>
          <el-input v-model="form.time_slot" placeholder="上课时间"></el-input>
        </el-form-item>
        <el-form-item label="预计上课日期" required>
          <el-date-picker
            v-model="appointTime"
            type="datetime"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="拼班截至时间" required>
          <el-date-picker
            v-model="endTime"
            type="datetime"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="form.id"
          type="primary"
          @click="updateForm"
          :loading="loading"
          >更 新</el-button
        >
        <el-button v-else type="primary" :loading="loading" @click="addForm"
          >新 增</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>

import { ObjectChangedValue } from "@/js/utils.js";

export default {
  data() {
    return {
      clear: false,
      form: {},
      temp: {},
      dialogVisible: false,
      loading: false,
      endTime: "",
      appointTime: "",

      activityList: [],
      DataList: [],
      Option: [
        { name: "名称", value: "title" },
        { name: "价格", value: "discount_amout" },
        { name: "课节数", value: "course_num" },
        { name: "类目", value: "categories_child" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      key: "",
      curCourse: {},

      mecid: "",
      categories: "体育运动",
    };
  },

  mounted() {},

  methods: {
    changeCourse(row) {
      this.curCourse = row;
      this.form.max_count = row.connect_peoplenum;
      this.DataList = [];
    },
    //获取列表
    getCourseList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/masterSetPrice/queryByMessage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: 2,
          type: "mechanism_offline",
          categories: this.categories,
          title: this.key,
          mechanism_id: this.mecid,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    addForm() {
      if (!this.form.max_count) {
        this.$message("拼班人数");
        return;
      }
      if (!this.endTime) {
        this.$message("请选择截至时间");
        return;
      }
      if (!this.curCourse.id) {
        this.$message("请选择课程");
        return;
      }

      let data = {
        ...this.form,
        course_id: this.curCourse.id,
        type: this.curCourse.type,
        start_time: new Date().Format("yyyy-MM-dd hh:mm:ss"),
        end_time: this.endTime + " 23:59:59",
        expect_start_time: this.appointTime + " 00:00:00",
        province: this.curCourse.province,
        city: this.curCourse.city,
        district: this.curCourse.district,
        address: this.curCourse.address,
        latitude: this.curCourse.latitude,
        longitude: this.curCourse.longitude,
      };

      this.loading = true;
      this.$axios({
        url: "/user/userCourseGroup/insert",
        method: "post",
        data: data,
      })
        .then((res) => {
          this.$notify({
            message: "新增成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
          this.loading = false;
          this.$emit("success", 1);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    updateForm() {
      if (this.form.type == "course") {
        this.form.course_id = this.curCourse.id || null;
      }

      const data = ObjectChangedValue(this.temp, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      this.loading = true;
      this.$axios({
        url: "/user/userCourseGroup/update",
        method: "post",
        data: {
          id: this.form.id,
          ...data,
        },
      })
        .then((res) => {
          this.$notify({
            message: "更新成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
          this.loading = false;
          this.$emit("success", 1);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    open(info) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
      } else {
        this.form = {};
        this.temp = {};
      }
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.bold{
  font-size: 15px;
  font-weight: 600;
}
</style>